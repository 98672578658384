import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import { TableCell } from "@material-ui/core";
import { moneyFormat } from "../../../pages/Pim/pages/Home/utils/productDataGenerator";

export const MerchantLogInfo = ({item}) => {

  const PriceChanges = ({item}) => {
    const was = moneyFormat(item.priceChanges.was, '$');
    const became = moneyFormat(item.priceChanges.became, '$');

    return (
      <>
        <div className="price-was-became">
          { was }
        </div>
        <div className="price-arrow">→</div>
        <div className="price-was-became">
          { became }
        </div>
      </>
    );
  };

  const QuantityChanges = ({item}) => {
    const was = item.stockQtyChanges.was;
    const became = item.stockQtyChanges.became;

    return (
      <>
        <div className="price-was-became">
          { was }
        </div>
        <div className="price-arrow">→</div>
        <div className="price-was-became">
          { became }
        </div>
      </>
    );
  };

  const Difference = ({was, became}) => {
    if (was && was > became) {
      const diff = Math.round(became * 100 / was);
      return (
        <div className="price-red-percent"><div className="price-vertical-arrow">↓</div> % { diff }</div>
      );
    } else if (became && became > was) {
      const diff = Math.round(was * 100 / became);
      return (
        <div className="price-green-percent"><div className="price-vertical-arrow">↑</div> % { diff }</div>);
    } else {
      return (
        <div className="price-null-percent">% 0</div>
      );
    }
  };

  return (
    <>
      <TableCell style={{fontSize: '18px', paddingTop: '5px'}}>
        {item.priceChanges !== null ? (
          <>
            <PriceChanges item={item} />
            <Difference was={item.priceChanges.was} became={item.priceChanges.became} />
          </>
        ) : (
          <IntlMessages id="title.notChanged" />
        ) }
      </TableCell>
      <TableCell style={{fontSize: '18px'}}>
        { item.stockQtyChanges !== null ? (
          <>
            <QuantityChanges item={item} />
            <Difference was={item.stockQtyChanges.was} became={item.stockQtyChanges.became} />
          </>
        ) : (
          <IntlMessages id="title.notChanged" />
        )}
      </TableCell>
      <TableCell style={{fontSize: '18px'}}>
        { item.createdAt }
      </TableCell>
    </>
  );
}
